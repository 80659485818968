<template>
  <div class="ed-container bg-F5F5F5  mh-100vh animate__animated animate__fadeIn animate__faster">
    <!-- 40 -->
    <div class="p-relative px-11 mt-10" style="height: 89px">
      <!-- 展品描述 -->
      <ExhibitorDetailCard :item="exhibitorInfo"></ExhibitorDetailCard>
    </div>
    <!-- 展品信息 展商详情 -->
    <div class="ed-wrapper">
      <ExhibitorTab :tabList="tabList" @changeTab="changeTab" :changeIndex="changeIndex"></ExhibitorTab>
      <!-- 内容展示 -->
      <div>
        <van-swipe class="my-swipe" @change="handleTabChange" :show-indicators="false" :loop="false" ref="vanSwipe">
          <van-swipe-item>
            <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false"
              :loading-text="loadingText" :finished-text="finishText" @load="handleReachBottom">
              <div class="pfi-box animate__animated animate__fadeIn animate__fast mt-10">
                <div class="px-10" v-show='changeIndex==0'>
                  <ExhibitsInfoItem v-for="(item, index) in exhibitsInfoList" :key="index" :item="item">
                  </ExhibitsInfoItem>
                </div>
              </div>
            </van-list>
          </van-swipe-item>
          <van-swipe-item>
            <ExhibitorInfo v-show='changeIndex==1' :item="exhibitorInfo" :contactArr="contactArr"></ExhibitorInfo>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center" style="z-index: 1000000;">
      <FirstLoading></FirstLoading>
    </div>
  </div>
</template>
<script>
import { getQueryExhibitorDetail } from '@/api/exhibitor'
import { getList } from '@/utils/common'
import { GetQueryString } from '@/utils/utils'
export default {
  props: {},
  components: {},
  data() {
    return {
      firstLoading: true,
      color: '#03A64D',
      tabList: [
        { id: 1, title: '展品信息' },
        { id: 2, title: '展商详情' },
      ],
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      // refreshing: true, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 100,
      },
      total: 0,
      pages: 0,
      contactArr: [],
      loadingText: '玩命加载中...',
      finishText: '没有更多了',
      changeIndex: 0,
      exhibitorCode: '', // 展商code
      exhibitsInfoList: [], // 展品信息
      exhibitorInfo: {}, // 展商详情
      // exhibitNum: 0
      // headers====
      eventKey: '',
      appKey: '',
      token: '',
      arr: []
    }
  },
  watch: {
    '$route': {
      handler() {
        console.log(`output->之幸福`)
      },
      deep: true
    }
  },
  methods: {
    // tab切换
    changeTab(index) {
      this.$refs.vanSwipe.swipeTo(index)
    },
    // tab切换
    handleTabChange(index) {
      this.changeIndex = index;
      if (index == 0) {
        this.params.pageNum = 1
        this.getList()
      }
    },
    // 获取query参数
    getQueryParams() {
      this.exhibitorCode = this.$route.query.exhibitorCode
      this.eventKey = GetQueryString('eventKey') || 'MEHZ'
      this.appKey = GetQueryString('appKey') || 'WX_MINI_MEHZ'
      this.token = GetQueryString('token') || ''
      localStorage.setItem('eventKey', this.eventKey)
      localStorage.setItem('appKey', this.appKey)
      localStorage.setItem('token', this.token)
    },
    async getData() {
      if (!this.exhibitorCode) return
      var headerClientInfo = { language: 'CN', eventKey: this.eventKey, appKey: this.appKey }
      let headers = {
        'x-call-client-info': JSON.stringify(headerClientInfo),
      }
      try {
        let resp = await getQueryExhibitorDetail(headers, this.exhibitorCode)
        console.log(`output->resp11111111111111111111`, resp)
        this.exhibitorInfo = resp.data
        this.arr = this.exhibitorInfo?.exhibitVoList || []
        this.contactArr = this.exhibitorInfo?.exhibitorContactVoList || []
        this.arr = this.arr.map(item => {
          let fileSuffix = item.cover.slice(item.cover.lastIndexOf('.') + 1)
          console.log(`output->fileSuffix`, fileSuffix)
          if (this.$videoSuffix.includes(fileSuffix)) {
            item.type = 'video'
          }
          item.type = 'img'
          return item
        })


        this.getList()
      } catch (error) {
        console.log(`output->error`, error)
      }
    },
    getList() {
      this.loading = true
      return getList(this.params, this.arr).then((res) => {
        this.exhibitsInfoList = this.params.pageNum == 1 ? [...res.list] : [...this.exhibitVoList, ...res.list]
        this.pages = res.pages
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.exhibitsInfoList.length ? '没有更多了' : '暂无更多数据'
      })
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        console.log(`output->进入`)
        this.finished = true
      }
    },
  },
  async mounted() {
    try {
      this.firstLoading = true
      await this.getQueryParams()
      await this.getData()
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
  beforeDestroy() {
  }
}
</script>
<style lang="less" scoped>
.ed-container {
  .van-tab--active {
    font-weight: 700;
  }

  .ed-wrapper {
    .van-swipe {
      min-height: calc(100vh - 131px);

      /deep/ .van-swipe__track {
        min-height: calc(100vh - 131px);
      }
    }
  }

  .pfi-box {
    width: 100%;
    // margin-top: 10px;
  }
}
</style>