/**
 * 获取 url 参数 问号后的参数
 * 如：xx.com?a=1&b=2
 * */
import Vue from 'vue'
export function GetQueryString(name) {
  var result = location.href.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return result[1];
}

export function GetAllParam(){
  // 创建一个对象来存储参数和值
  const params = {};
  const paramsString = location.href.split('?')[1] || '';
  if(!paramsString){
    return;
  }
  const paramsArray = paramsString.split('&');
  // 遍历参数数组并将它们分割为键和值
  paramsArray.forEach(param => {
    const [key, value] = param.split('=');
    params[key] = value;
  });
  console.log(params)
  return params;
}

// 换肤 根据平台控制
export function changeSkin(r) {
  let color = ''
  console.log(r)
  switch (r) {
    case 'MEHZ': 
      color = '#05a64c'; 
      break;
    case 'MEETSG':
    case 'WEMEET':
      color = '#5cc2d0'; 
      break;
    default:
      localStorage.removeItem('eventkey');
      break;      
  }
  // 缓存平台
  localStorage.setItem('eventkey',r)
  // 修改全局主题色
  document.documentElement.style.setProperty('--theme-color', color)
}


export function loadImageEnd(list, callback, basePath) {
  if (!list || list.length === 0) return;
  if (basePath) list = list.map(item => basePath + item);
  let img = new Image();
  img.data = {
    list: list,
    callback: callback,
    resultList: [],
    num: 0
  }
  img.addEventListener("load", loadImgHandler);
  img.addEventListener("error", loadImgHandler);
  img.src = list[img.data.num];
}

function loadImgHandler(e) {
  let data = e.currentTarget.data;
  if (e.type !== 'error') {
    data.resultList.push(e.currentTarget.cloneNode(false));
  }
  data.num++;
  if (data.num > data.list.length - 1) {
    e.currentTarget.removeEventListener("load", loadImgHandler);
    if (data.callback) {
      data.callback(data.resultList);
    }
    data = null;
    return;
  }
  e.currentTarget.src = data.list[data.num];
}




export const getQueryStringBySearch = (search, name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = search.substr(1).match(reg);
  if (r != null) {
    return encodeURIComponent(r[2]);
  }
  return null;
}

export function routerModule(path,param){
  this.$router.push({
    path:path,
    query:param
  })
}

export function replaceMoudule(path,param){
  this.$router.replace({
    path:path,
    query:param
  })
}

/**
 * 使用场景: 鼠标不断点击触发，输入框中不断输入(单位时间内只触发一次)。
 * @description: 防抖函数
 * @param {Function} fn 
 * @param {Number} wait 
 * @return fn 函数
 */

export function debounce(fn, delay = 350) {
  let timer = null;
  return function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

/**
 * @description: 转换为query参数
 * @param {Object} opt 
 * @returns query参数
 */
export function queryParams(opt) {
  let params = opt ? ('?' + Object.keys(opt).map(key => key + '=' + opt[key]).join(
    '&')) : ''
  return params
}
Vue.prototype.$routerModule = routerModule
Vue.prototype.$replaceMoudule = replaceMoudule
Vue.prototype.$queryParams = queryParams
