<template>
  <!-- 头部搜索 -->
  <div class="border-box bg-white fixed-top d-flex px-10 py-10 a-center" style="height: 56px;">
    <div class="search-box flex-1 bgColor border-box p-relative px-15">
    <van-search
      v-model="valueText"
      :placeholder="placeholderText"
      @search="handleSearch"
    >
    <template  #left-icon	>
         <span class="iconfont h5sousuo p-relative" style="font-size: 20px;top:2px;color:#CCCCCC" @click="handleSearch"></span>
    </template>
      
    </van-search>
    </div>
  </div>
</template>
<script>
import {  debounce } from '@/utils/utils'
export default {
  name: 'HeaderSearch',
  props: {
    placeholderText: {
      type: String,
      default: '企业名称',
    },
    keywords: {
      type: String,
      default: '',
    },
  },
  components: {},
  data() {
    return {
      valueText:this.keywords
    }
  },
  methods: {
    handleSearch:debounce(function(e) {
      // 一定是input事件
      this.$emit("handleChange",this.valueText)
    }),
    onCancel() {
      console.log(`output->取消`)
    }
  },
  
  
  mounted() {},
}
</script>
<style lang="less" scoped>
.search-box {
  width: 100%;
  height: 37px;
  border-radius: 20px;
  .van-search {
    height: 37px;
    background-color: #F5F5F5;
    box-sizing: border-box;
    padding: 0;
    .van-search__content  {
      padding-left: 0;
      .van-cell  {
        display: flex;
        align-items: center;
        height: 37px;
        .van-cell__value {
          display: flex;
          align-items: center;
        }
        /deep/ .van-field__left-icon {
          margin-right: 10px;
        }
      }
     
    }
  }
  // .set-field {
  //   width: 90%;
  //   height: 37px;
  //   margin-left: 27px;
  //   background-color: #F5F5F5;
 
  // }
  // .van-cell {
  //     padding: 10px 2px !important;
  //   }
  input::-webkit-input-placeholder {
    color: #cccccc;
    font-size: 13px;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #cccccc;
    font-size: 13px;
  }
  　　 input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #cccccc;
    font-size: 13px;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cccccc;
    font-size: 13px;
  }
}
</style>