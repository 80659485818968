import http from '@/utils/http'
import { queryParams } from '@/utils/utils'

// 查询展商列表
export function getQueryExhibitorList(headers, params) {
  const config = {
    method: 'GET',
    url:  `/user/exhibitor/queryList${params}`,
    headers
  }
  return http(config)
}
// 查询展商详情
export function getQueryExhibitorDetail(headers, exhibitorCode) {
  const config = {
    method: 'GET',
    url: `/user/exhibitor/query/detail?exhibitorCode=${exhibitorCode}`,
    headers
  }
  return http(config)
}

// 查询展品列表
export function getQueryExhibitsList(headers, params) {
  const config = {
    method: 'GET',
    url: `/user/exhibit/queryList${params}`,
    headers
  }
  return http(config)
}
// 查询展会下所有的展品列表
export function getQueryListByMeetingCode(headers, params) {
  const config = {
    method: 'GET',
    url: `/user/exhibit/queryListByMeetingCode${params}`,
    headers
  }
  return http(config)
}
// 查询展品详情
export function getQueryExhibitsDetail(headers, exhibitCode) {
  const config = {
    method: 'GET',
    url: `/user/exhibit/query/detail?exhibitCode=${exhibitCode}`,
    headers
  }
  return http(config)
}
