<template>
  <div class="mh-100vh exhibits-list bgColor p-relative animate__animated animate__fadeIn animate__faster">
    <van-pull-refresh v-model="refreshing" @refresh="handlePullDownRefresh">
      <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" :loading-text="loadingText"
        :finished-text="finishText" @load="handleReachBottom">
        <div style="height: 56px;"></div>
        <!-- 头部 -->
        <HeaderSearch :placeholderText="placeholderText" :keywords="params.likeName" @handleChange="handleChange">
        </HeaderSearch>
        <!-- 展品列表 -->
        <div class="pfi-box">
          <div class="list">
            <ExhibitsInfoItem v-for="(item, index) in list" :key="index" :item="item">
            </ExhibitsInfoItem>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center" style="z-index: 1000000;">
      <FirstLoading></FirstLoading>
    </div>
  </div>
</template>
<script>
import { getQueryExhibitsList, getQueryListByMeetingCode } from '@/api/exhibitor'
import { GetQueryString } from '@/utils/utils'
export default {
  data() {
    return {
      firstLoading: true,
      placeholderText: '商品名称',
      list: [],
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: false, // 下拉状态是否处于加载中状态
      params: {
        exhibitorCode: '',
        meetingCode: '',
        pageNum: 1,
        pageSize: 12,
        likeName: '',
      },
      total: 0,
      pages: 0,
      loadingText: '玩命加载中...',
      finishText: '没有更多了',
      // mockData: [
      //   {
      //     banner: require('../../assets/images/search-logo.png'),
      //     brandName: '品牌名称',
      //     cover: require('../../assets/images/search-logo.png'),
      //     createTime: '创建时间',
      //     exhibitCategory: '产品类目',
      //     exhibitCode: '展品code',
      //     exhibitId: 0,
      //     exhibitName: '展品名称',
      //     exhibitorName: '展商名称',
      //     selfSort: 0,
      //     sort: 0,
      //     status: true,
      //   },
      // ],
      // headers====
      eventKey: '',
      appKey: '',
      token: '',
    }
  },
  created() { },
  methods: {
    getQueryParams() {
      let exhibitorCode = GetQueryString('exhibitorCode') || ''
      let meetingCode = GetQueryString('meetingCode') || ''
      if (exhibitorCode || meetingCode) {
        this.params.exhibitorCode = exhibitorCode
        this.params.meetingCode = meetingCode
        this.eventKey = GetQueryString('eventKey') || 'MEHZ'
        this.appKey = GetQueryString('appKey') || 'WX_MINI_MEHZ'
        this.token = GetQueryString('token') || ''
        localStorage.setItem('eventKey', this.eventKey)
        localStorage.setItem('appKey', this.appKey)
        localStorage.setItem('token', this.token)
        localStorage.setItem('exhibitorCode', this.params.exhibitorCode)
        localStorage.setItem('meetingCode', this.params.meetingCode)
      } else {
        console.log(`output->exhibitorCode`)
        this.params.exhibitorCode = 'ER1684822570953695232'
        console.log(`output->exhibitorCode`, this.params.exhibitorCode)
      }
    },
    handleChange(e) {
      console.log(`output->e`, e)
      this.params.pageNum = 1
      this.params.likeName = e
      this.list = []
      this.getData()
    },
    getParams() {
      let obj;
      if (this.params.meetingCode) {
        obj = {
          meetingCode: this.params.meetingCode,
          pageNum: this.params.pageNum,
          pageSize: this.params.pageSize,
          likeName: this.params.likeName,
        }
      } else {
        obj = {
          exhibitorCode: this.params.exhibitorCode,
          pageNum: this.params.pageNum,
          pageSize: this.params.pageSize,
          likeName: this.params.likeName,
        }
      }
      return obj
    },
    async getData() {
      var headerClientInfo = { language: 'CN', eventKey: this.eventKey, appKey: this.appKey }
      let headers = {
        'x-call-client-info': JSON.stringify(headerClientInfo),
      }
      try {
        this.loading = true
        let obj = this.getParams()
        let params = this.$queryParams(obj)
        console.log(`output->params`, params)
        let api = (this.params.meetingCode && !this.params.exhibitorCode) ? getQueryListByMeetingCode : getQueryExhibitsList
        console.log(`output->api`, api)
        let { data } = await api(headers, params)

        if (data && data.list.length) {
          data.list = data.list.map(item => {
            let fileSuffix = item.cover.slice(item.cover.lastIndexOf('.') + 1)
            console.log(`output->fileSuffix`, fileSuffix)
            console.log(`output->this.$videoSuffix`, this.$videoSuffix)
            if (this.$videoSuffix.includes(fileSuffix)) {
              item.type = 'video'
            }
            item.type = 'img'
            return item
          })
          this.list = this.params.pageNum == 1 ? [...data.list] : [...this.list, ...data.list]
          this.total = data.total
          this.pages = data.pages
          // 追加完成后关闭loading
          this.loading = false
          this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
        } else {
          console.log(`output->假数据`)
          // this.list = this.mockData
          // // 追加完成后关闭loading
          this.loading = false
          this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
        }
      } catch (error) {
        if (this.params.pageNum > 0) {
          this.params.pageNum--
          this.loading = true
        }
        console.log(`output->error`, error)
      }
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        this.finished = true
      }
    },
    // 下拉刷新
    handlePullDownRefresh() {
      setTimeout(() => {
        this.params.pageNum = 1
        this.getData()
        this.refreshing = false
        this.finished = false // 重新加载
        if (!this.refreshing) {
          this.$toast.success('刷新成功')
        }
      }, 1000)
    },
  },
  async mounted() {
    try {
      this.firstLoading = true
      await this.getQueryParams()
      await this.getData()
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
}
</script>
<style lang="less" scoped>
.exhibits-list {
  .van-pull-refresh {
    min-height: 100vh;

    .pfi-box {
      width: 100%;
      margin-top: 10px;

      .list {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
      }
    }
  }
}</style>