<template>
    <div>
        加载中...
    </div>
</template>
<script>
import { GetQueryString, changeSkin, GetAllParam } from '@/utils/utils'
export default {
    data() {
        return {

        }
    },
    created() {
        let eventkey = GetQueryString('eventkey') || ''   
        let pageName = GetQueryString('pageName') || ''
        changeSkin(eventkey)
        let param = GetAllParam() || {}
        delete param['pageName']
        console.log(param)
        this.jump(pageName,param)
    },
    computed: {

    },
    methods: {
        jump(pageName,param) {
            console.log(param)
            switch (pageName) {
                case "exhibitor":
                    this.$replaceMoudule('/exhibitor/index',param);
                    break;
            }
        },
    }
}
</script>