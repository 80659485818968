<template>
  <transition name="fade">
    <div v-if="show" class="zhezhao">
      <div class="box">
        <div class="indicator">{{currentIndex+1 }}/{{ imgs.length }}</div>
      </div>
      <div style="width:100%;height:100%;position:relative;" @touchstart="startChange" @touchend="changeImg">
        <img :src="current" :class="{scaleImg:current ==imgs[currentIndex]}">
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    name: 'PreviewImg',
    props: {
      imgs: {
        default: [],
        type: Array
      },
      currentImg: {
        default: '',
        type: String
      },
      show: {
        default: false,
        type: Boolean
      }
    },
    data () {
      return {
        current: '',
        currentX: '',
        currentIndex: 0, // 当前索引
      }
    },
    watch: {
      currentImg (val,old) {
        this.current = this.currentImg
        this.getIndex(val)
      }
    },
    mounted () {
    },
    methods: {
      startChange (e) {
        this.currentX = e.changedTouches[0].clientX
      },
      changeImg (e) {
        let distance = e.changedTouches[0].clientX - this.currentX
        if (-10< distance && distance <10) {
          this.$emit('close')
        } else if (distance < -50) {
          this.swipeLeft()
        } else {
          this.swipeRight()
        }
        console.log(`output->this.currentIndex`,this.currentIndex)
        console.log(`output->this.imgs[this.currentIndex]`,this.imgs[this.currentIndex])
      },
      getIndex (url) {
        for (let i = 0,len = this.imgs.length;i < len;i ++) {
          if (this.imgs[i] == url) {
            this.currentIndex = i
            break
          }
        }
      },
      swipeLeft () {
        if (this.currentIndex != (this.imgs.length - 1)) {
          this.currentIndex++
        } else {
          this.currentIndex = 0
        }
        this.current = this.imgs[this.currentIndex]
      },
      swipeRight () {
        if (this.currentIndex == 0) {
          this.currentIndex = this.imgs.length - 1
        } else {
          this.currentIndex--
        }
        this.current = this.imgs[this.currentIndex]
      },
    }
  }
</script>
<style scoped>
  .zhezhao {
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: #000;
    z-index: 10000;
  }
  .box {
    position: fixed;
    top: 30px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    z-index: 10001
  }
  .indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    color: #fff;
    transition: all .3s  ease-in-out
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .scaleImg {
    animation: scale .8s ease both;
  }
  @keyframes scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }
  }
</style>