<template>
  <div class="exhibits-detail mh-100vh bgColor animate__animated animate__fadeIn animate__faster">
    <!-- 轮播图 -->
    <ExhibitsCarousel :carouselArr="carouselArr"></ExhibitsCarousel>
    <!-- 名称 -->
    <div class="title-box mt-10 mx-10">
      <div class="title">{{ exhibitsInfo?.exhibitName }}</div>
      <div class="desc" v-if="productType">类型：{{ productType }}</div>
    </div>
    <!-- card -->
    <div class="card-desc mx-10">
      <div class="d-flex bg-white p-10 border-box mb-10" @click="handleJump(exhibitsInfo?.exhibitorVo)">
        <img class="d-block border-box flex-shrink-0 mr-10" v-lazy="exhibitsInfo?.exhibitorVo?.logo" alt=""
          style="width:56px;height: 56px; border-radius:4px;">
        <div class="flex-1 d-flex flex-column flex-wrap ml-auto" style="margin-right: 5px;">
          <div class="fz-14 fw-5" style="color: #292929">{{ exhibitsInfo?.exhibitorVo?.exhibitorName || "暂无" }}</div>
          <div class="fz-12" style="margin-top: 8px;color: #858585">{{ exhibitsInfo?.exhibitorVo?.businessCategory ||
      "暂无"
            }}</div>
          <div class="fz-12 mt-auto" style="color: #858585">共{{ exhibitsInfo?.exhibitorVo?.exhibitNum || 0 }}个展品</div>
        </div>
        <span class="ml-auto iconfont  h5xiangyoujiantou  flex-shrink-0 a-self-center "
          style="color:#D8D8D8;width: 21px;height: 21px;font-size:20px"></span>
      </div>
    </div>
    <div v-if="attributeList.length" class="mx-10 card-desc">
      <div class="border-box bg-white" style="background-color: #fff;margin-top: 11px;">
        <div class="fz-14 fw-7 cor-262626 mb-10">属性</div>
        <div class="d-flex a-center flex-wrap">
          <div class="d-flex flex-wrap" style="width: 100%;">
            <block v-for="(v, i) in attributeList" :key="i">
              <div @click="chooseAttr(i)" class="fz-14  mb-10 mr-10 d-flex flex-center lh-11"
                :class="v.isCheck ? 'text-white' : 'cor-9E9E9E'" style="height: 54rpx;max-width: 280px; background: #F5F5F5; border-radius: 2px;padding: 4px 8px;">
                {{ v.name }}</div>
            </block>
          </div>
        </div>
      </div>
    </div>


    <!-- tab -->
    <ExhibitorTab :tabList="tabList" @changeTab="changeTab" :changeIndex="changeIndex"></ExhibitorTab>
    <div class="">
      <van-swipe class="my-swipe" @change="handleTabChange" :show-indicators="false" :loop="false" ref="vanSwipe">
        <van-swipe-item>
          <!-- 展品详情 -->
          <div class="mt-10" v-show='changeIndex==0'>
            <ExhibitsTextDesc
              :details="exhibitsInfo?.exhibitorVo?.enterpriseCode ? exhibitsInfo?.detailsToc : exhibitsInfo?.details">
            </ExhibitsTextDesc>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <!-- 其他展品 -->
          <van-list v-show='changeIndex==1' v-model="loading" :finished="finished" :offset="10" :immediate-check="false"
            :loading-text="loadingText" :finished-text="finishText" @load="handleReachBottom">
            <div class="pfi-box animate__animated animate__fadeIn animate__fast">
              <div class="px-10">
                <ExhibitsInfoItem v-for="(item, index) in exhibitsInfoList" :key="index" :item="item">
                </ExhibitsInfoItem>
              </div>
            </div>
          </van-list>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center" style="z-index: 1000000;">
      <FirstLoading></FirstLoading>
    </div>
  </div>
</template>
<script>
import ExhibitsCarousel from './components/ExhibitsCarousel.vue'
import ExhibitsTextDesc from './components/ExhibitsTextDesc.vue'
import { getQueryExhibitsDetail } from '@/api/exhibitor'
import { getList } from '@/utils/common'
import { GetQueryString } from '@/utils/utils'
export default {
  data() {
    return {
      firstLoading: true,
      tabList: [
        { id: 1, title: '展品详情' },
        { id: 2, title: '其他展品' },
      ],
      changeIndex: 0,
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      // refreshing: true, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      pages: 0,
      loadingText: '玩命加载中...',
      finishText: '没有更多了',
      exhibitCode: '', // 展品code
      exhibitsInfo: {}, // 展品详情
      exhibitsInfoList: [], // 其他展品
      attributeList: [],
      productType: '',
      carouselArr: [], // 轮播图
      arr: [],
      // headers====
      eventKey: '',
      appKey: '',
      token: ''
    }
  },
  components: {
    ExhibitsCarousel,
    ExhibitsTextDesc,
  },
  watch: {
    '$route': {
      handler() {
        console.log(`output->之幸福`)
        try {
          this.firstLoading = true
          this.getQueryParams()
          this.getData()
          setTimeout(() => {
            this.firstLoading = false
          }, 300)
        } catch (error) {
          setTimeout(() => {
            this.firstLoading = false
          }, 300)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() { },
  methods: {
    // 跳转展商详情页面
    handleJump(item) {
      console.log(item.enterpriseCode)
      // if (item.enterpriseCode) {
      //   wx.miniProgram.navigateTo({
      //     url: `/subPackage/enterprise/pages/main-page/index?enterpriseCode=${item.enterpriseCode}`
      //   })
      //   return false
      // }
      this.$router.push({
        path: '/exhibitor/exhibitorDetail',
        query: {
          exhibitorCode: item.exhibitorCode,
        },
      })
    },
    // tab切换
    changeTab(index) {
      this.$refs.vanSwipe.swipeTo(index)
    },
    // tab切换
    handleTabChange(index) {
      this.changeIndex = index;
      if (index == 1) {
        this.params.pageNum = 1
        this.getList()
      }
    },
    // 获取query参数
    getQueryParams() {
      this.exhibitCode = this.$route.query.exhibitCode
      this.eventKey = GetQueryString('eventKey') || 'MEHZ'
      this.appKey = GetQueryString('appKey') || 'WX_MINI_MEHZ'
      this.token = GetQueryString('token') || ''
      localStorage.setItem('eventKey', this.eventKey)
      localStorage.setItem('appKey', this.appKey)
      localStorage.setItem('token', this.token)
    },
    async getData() {
      if (!this.exhibitCode) return
      var headerClientInfo = { language: 'CN', eventKey: this.eventKey, appKey: this.appKey }
      let headers = {
        'x-call-client-info': JSON.stringify(headerClientInfo),
      }
      try {
        let resp = await getQueryExhibitsDetail(headers, this.exhibitCode)
        if (resp.code !== 6000) {
          console.log(`output->resp`, resp)
          this.exhibitsInfo = resp.data
          let banner = this.exhibitsInfo?.attachmentVos
          if (resp.data.productAttributeVos.length) {
            resp.data.productAttributeVos.map((res) => {
              if (res.type != 'TYPE') {
                this.attributeList.push(res)
              } else {
                this.productType = res.name
              }
            })
          }


          this.carouselArr = banner.map(v => {
            let fileSuffix = v.fileUrl.slice(v.fileUrl.lastIndexOf('.') + 1)
            console.log(`output->fileSuffix`, fileSuffix)
            if (this.$videoSuffix.includes(fileSuffix)) {
              return {
                type: 'video',
                src: v.fileUrl
              }
            }
            return {
              type: 'img',
              src: v.fileUrl
            }
          })
          console.log(`output->222222222222222222222`, this.carouselArr)
          this.arr = this.exhibitsInfo?.exhibitVoList || []
          this.arr = this.arr.map(item => {
            let fileSuffix = item.cover.slice(item.cover.lastIndexOf('.') + 1)
            if (this.$videoSuffix.includes(fileSuffix)) {
              item.type = 'video'
            }
            item.type = 'img'
            return item
          })
          this.getList()
        } else {
          this.$toast.fail(resp?.msg || "非法参数")
          setTimeout(() => {
            this.$router.go(-1)
          })
        }

      } catch (error) {
        console.log(`output->error`, error)
        this.$toast.fail(error?.msg || "非法参数")
        setTimeout(() => {
          this.$router.go(-1)
        })
      } finally {
      }
    },
    getList() {
      this.loading = true
      return getList(this.params, this.arr, 150).then((res) => {
        console.log(`output->res`, res)
        this.exhibitsInfoList = this.params.pageNum == 1 ? [...res.list] : [...this.exhibitsInfoList, ...res.list]
        this.pages = res.pages
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.exhibitsInfoList.length ? '没有更多了' : '暂无更多数据'
      })
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        console.log(`output->进入`)
        this.finished = true
      }
    },
  },
  mounted() { },
}
</script>
<style lang="less" scoped>
.exhibits-detail {
  padding-bottom: 20px;

  .title-box {
    background-color: #fff;
    border-radius: 8px;
    padding: 22px 10px 22px;
    box-sizing: border-box;
    margin-top: 10px;

    .title {
      font-size: 18px;
      font-weight: 700;
      color: #292929;
    }

    .desc {
      margin-top: 8px;
      color: #858585;
      font-size: 13px;
    }
  }

  .card-desc {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 15px 10px 10px;
    box-sizing: border-box;
    margin-top: 10px;
  }

  .van-swipe {
    min-height: 248px;

    /deep/ .van-swipe__track {
      min-height: 248px;
    }
  }

  .pfi-box {
    width: 100%;
    // margin-top: 10px;

  }
}
</style>