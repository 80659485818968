<template>
  <div class="tab-list d-flex left-0 right-0 bg-white mt-10" >
      <div 
          class="tab-item d-flex flex-1 flex-center "
          :class="changeIndex == index ? 'cor-262626 fw-7' : 'cor-9E9E9E'"
          v-for="(item,index) in tabList"
          :key="index"
          @click="changeTab(index)"
      >
        <div class="title a-self-stretch">
          <div>{{ item.title }}</div>
          <div v-if="changeIndex == index" class="line  animate__animated animate__zoomIn animate__faster" style="background: #00C4C9;"></div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name:"ExhibitorTab",
  props:{
    changeIndex: {
      type: [Number,String],
      default: 0
    },
    tabList: {
      type: Array,
      default: ()=> []
    },
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    changeTab(index) {
      this.$emit("changeTab",index)
    }
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
  .tab-list {
    height: 32px;
    .tab-item {
      height: 32px;
      position: relative;
      &.active {
        font-weight: 700;
        color: #262626;
      }
      .title {
        font-size: 14px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .line {
          width: 20%;
          height: 2px;
          position: absolute;
          bottom: 0;
          border-radius: 30px;
        }
      }
    }
  }
</style>