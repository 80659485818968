<template>
  <div class="bg-white d-flex border-box p-10" style="border-radius: 10px;min-height: 89px">
    <img class="flex-shrink-0 mr-10" :src="item.logo" alt="" style="width: 70px;height:70px;border-radius: 12px;">
    <div class="flex-1">
      <div class="fw-7 cor-262626 fz-13">{{ item.exhibitorName }}</div>
      <div class="cor-8C8C8C mt-5 fz-11" v-if="item.booth">{{ item.booth  }}</div>
      <div class="d-flex flex-wrap a-center" style="margin-top: 4px;" v-if="item.businessCategory" >
        <div class="cor-9E9E9E d-flex flex-center  fz-11" style="padding: 2px 4px;height: 17px;border-radius: 3px;border: 1px solid #E0E0E0;
  ">{{ item.businessCategory }}</div>
      <div class="fz-14 a-self-end ml-auto" style="color: #00C4C9;padding: 5px 12px; border: 1px solid #00C4C9; border-radius: 6px;" v-if="item.enterpriseCode" @click.stop="enterEnterpirse(item)">进入荟客厅</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"ExhibitorDetailCard",
  props:{
    item:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  components:{},
  data() {
    return { }
  },
  mounted() {},
  computed: {},
  watch:{},
  methods: {
    enterEnterpirse(item){
      wx.miniProgram.navigateTo({
          url:`/subPackage/enterprise/pages/main-page/index?enterpriseCode=${item.enterpriseCode}&source=${this.meetingName}&way=shareMeeting`
        })
    }
  },
  mounted() {}
  
}
</script>
<style scoped lang="less"></style>