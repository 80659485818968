<template>
  <div class="etd-container animate__animated animate__fadeIn animate__fast">
    <div class="content mx-10 border-box bg-white" v-html="details">
    </div>
  </div>
</template>
<script>
export default {
  props:{
    details:{
      type:String,
      default:''
    }
  },
  components:{},
  data() {
    return {}
  },
  methods: {},
  mounted() {}
}
</script>
<style lang="less" scoped>
.etd-container {
  .content {
    padding: 16px 14px 44px;
    border-radius: 7px;
    color: #858585;
    font-size: 13px;
    line-height: 1.2;
  }
}
</style>