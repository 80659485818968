<template>
 <div class="ec-carousel-box">
  <van-swipe
    :autoplay="autoplay"
    class="my-swipe" 
    indicator-color="white"
    :show-indicators="showIndicators"
    @change="handleChange"
    :loop="loop"
    :indicatorColor="indicatorColor"
  >
    <van-swipe-item v-for="(v,index) in carouselArr" :key="index">
      <img v-if="v.type == 'img'" class="d-block"  v-lazy="v.src" alt="" @click="handlePreviewImg(v.src)">
      <video 
      v-else
       :ref="`video${index}`"
       class="d-block video" 
       :src="v.src"
        webkit-playsinline 
        x-webkit-airplay
        playsInline
        x5-playsinline
        x5-video-orientation="portrait"
        x5-video-player-type="h5"
        x5-video-player-fullscreen
        @play="play"
        @pause="pause"
       controls></video>
    </van-swipe-item>
  </van-swipe>
  <!-- <div class="indicator d-flex flex-center" v-if="carouselArr.length">
    <span>{{ carouselArr[changeIndex].type == 'img' ? '图片' :'视频' }}</span>
    <span>{{ carouselNum }}</span>
  </div> -->
  <!-- 预览图片 -->
  <previewImg :show="isShowPreviewImg" :imgs="getImgArr" :currentImg="currentImg" @close="isShowPreviewImg = false"></previewImg>
 </div>
</template>
<script>
export default {
  props:{
    carouselArr:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      showIndicators:true,
      changeIndex: 0,
      autoplay: 3000,
      loop: true,
      video: '',
      indicatorColor:'',
      isShowPreviewImg: false, // 是否显示图片预览组件
      currentImg:'', // 当前图片
    }
  },
  computed: {
    carouselNum() {
      console.log(`output->执行`,this.carouselArr)
      return this.carouselArr.length ? `${this.changeIndex+1}/${this.carouselArr.length}` : 0
    },
    getImgArr() {
      let arr = this.carouselArr.length ?  [...this.carouselArr] : []
      console.log(`output->arr`,arr.filter(item=>item.type == 'img').map(item=>item.src))
      return arr.filter(item=>item.type == 'img').map(item=>item.src)
    }
  },
  methods: {
    // 图片预览事件
    handlePreviewImg (currentImg) {
      this.currentImg = currentImg
      this.isShowPreviewImg = true
    },
    setIndicatorColor() {
      this.$nextTick(()=>{
        this.indicatorColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color'); // #999999
      })
    },
    handleChange(index) {
      this.changeIndex = index
      let item = this.carouselArr[this.changeIndex]
      if(item.type == 'video') {
          this.video = this.$refs['video'+this.changeIndex][0]
          if(this.video.play()) {
            console.log(`output->暂停播放11111111111`)
            this.video.pause()
          }
      } else {
        if(this.video) {
          if(this.video.play()) {
            console.log(`output->暂停播放2222`)
            this.video.pause()
          }
        }
      }
    },
    play() {
      console.log(`play->播放触发`)
      this.autoplay = 0
    },
    pause() {
      console.log(`pause->播放暂停`)
      this.autoplay = 3000
    },
  },
  watch:{
    isShowPreviewImg() {
      this.autoplay =  this.isShowPreviewImg ? 0 : 3000
    }
  },
  mounted() {
    this.setIndicatorColor()
  },
}
</script>
<style lang="less" scoped>
  .ec-carousel-box {
    position: relative;
    .my-swipe {
      .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        video {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 0 0;
        }
      }
    }
    // .indicator {
    //   position: absolute;
    //   bottom: 16px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   min-width: 51px;
    //   min-height: 17px;
    //   background: rgba(0,0,0,0.4);
    //   border-radius: 8px;
    //   padding: 3px 5px;
    //   color: #fff;
    // }
  }
</style>