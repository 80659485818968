<template>
  <div class="exhibitor-list mh-100vh bgColor p-relative animate__animated animate__fadeIn animate__faster">
    <van-pull-refresh 
      v-model="refreshing" 
      @refresh="handlePullDownRefresh"
    >
      <van-list 
        v-model="loading" 
        :finished="finished" 
        :offset="10" 
        :immediate-check="false" 
        :loading-text="loadingText" 
        :finished-text="finishText" 
        @load="handleReachBottom"
      >
        <div style="height: 56px;"></div>
        <!-- 头部 -->
        <HeaderSearch placeholderText="企业名称" :keywords="params.likeExhibitorName" @handleChange="handleChange">
        </HeaderSearch>
        <!-- 展商列表 -->
        <div class="border-box list-wrapper px-10">
          <div class="list bg-white mt-10" v-for="(item,index) in list" :key="index">
            <ExhibitorItem :meetingName="meetingName" :item="item"></ExhibitorItem>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center"
				style="z-index: 1000000;">
				<FirstLoading></FirstLoading>
    </div>
  </div>
</template>
<script>
import { getQueryExhibitorList } from '@/api/exhibitor'
import { GetQueryString } from '@/utils/utils'
export default {
  data() {
    return {
      firstLoading: true,
      list: [],
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: false, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 12,
        likeExhibitorName: '',
        meetingCode: '',
      },
      meetingName:'',
      total: 0, // 总条数
      pages: 0, // 总页数
      loadingText: '玩命加载中...',
      finishText: '没有更多了',
      // headers====
      eventKey: '',
      appKey: '',
      token: '',
     
    }
  },
  created() {},
  methods: {
    getQueryParams() {
      let meetingCode = GetQueryString('meetingCode')
      if (meetingCode) {
        this.params.meetingCode = meetingCode
        this.eventKey = GetQueryString('eventKey') || 'MEHZ'
        this.meetingName = GetQueryString('meetingName') || ''
        this.appKey = GetQueryString('appKey') || 'WX_MINI_MEHZ'
        this.token = GetQueryString('token') || ''
        localStorage.setItem('meetingCode',this.params.meetingCode)
        localStorage.setItem('eventKey',this.eventKey)
        localStorage.setItem('appKey',this.appKey)
        localStorage.setItem('token',this.token)
      } else {
        console.log(`output->meetingCode`)
        this.params.meetingCode = 'EX1684482202806546432'
      }
    },
    handleChange(e) {
      console.log(`output->e`, e)
      // if(!e.trim()) return this.$toast("请输入企业名称~")
      this.params.pageNum = 1
      this.params.likeExhibitorName = e
      this.list = []
      this.getData()
    },

    async getData() {
      if(!this.params.meetingCode) return this.$toast.fail("非法参数")
      var headerClientInfo = { language: 'CN', eventKey: this.eventKey, appKey: this.appKey }
      let headers = {
        'x-call-client-info': JSON.stringify(headerClientInfo),
      }
      try {
        this.loading = true
        let params = this.$queryParams(this.params)
        let { data } = await getQueryExhibitorList(headers, params)
        this.list = this.params.pageNum == 1 ? [...data.list] : [...this.list, ...data.list]
        this.total = data.total
        this.pages = data.pages
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
      } catch (error) {
        if(this.params.pageNum>0) {
          this.params.pageNum--
        }
        console.log(`output->error`, error)
      }
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        this.finished = true
      }
    },
    // 下拉刷新
    handlePullDownRefresh() {
      setTimeout(() => {
        this.params.pageNum = 1
        this.getData()
        this.refreshing = false
        this.finished = false // 重新加载
        if (!this.refreshing) {
          this.$toast.success('刷新成功')
        }
      }, 1000)
    },
  },
  async mounted() {
    try {
      this.firstLoading = true
      await this.getQueryParams()
      await this.getData()
      setTimeout(()=>{
        this.firstLoading = false
      },300)
    } catch (error) {
      setTimeout(()=>{
        this.firstLoading = false
      },300)
    }
  },
}
</script>
<style lang="less" scoped>
.exhibitor-list {
  .van-pull-refresh {
    min-height: 100vh
  }
}

</style>