function changeStr (str){
  // 转换为大写的
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const requireComponent = require.context('../components/',false,/\.vue$/); // './'操作对象为当前目录
export default {
  install(app) {
    requireComponent.keys().forEach(element => {
      const config = requireComponent(element);
      const componentName = changeStr(
          element.replace(/^\.\//,'').replace(/\.\w+$/,'')
      )
      app.component(componentName, config.default || config)
  });
  }
}

