<template>
	<div class="flex f-center pb-6 mt-12">
		<CommonLoading customClass="mr-8"></CommonLoading>
		<div class="fz-12 cor-9d9d9d">玩命加载中..</div>
	</div>
</template>

<script>
	export default {
		name:"FirstLoading",
		data() {
			return {
				
			};
		}
	}
</script>
