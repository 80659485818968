import { render, staticRenderFns } from "./ExhibitsCarousel.vue?vue&type=template&id=023ab7bd&scoped=true&"
import script from "./ExhibitsCarousel.vue?vue&type=script&lang=js&"
export * from "./ExhibitsCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ExhibitsCarousel.vue?vue&type=style&index=0&id=023ab7bd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023ab7bd",
  null
  
)

export default component.exports