import Vue from 'vue'
/**
 * @description: 前端模拟后端实现分页功能函数
 * @param {Object} obj 
 * @param {Array} arr 
 * @returns {Promise}
 */
export const getList = (obj = {
	pageNum: 1,
	pageSize: 10,
	keywords: ''
}, arr,delay=500) => {
	let {
		pageNum,
		pageSize,
		keywords=""
	} = obj
	let list = []
	if (arr.length) {
		let searchList = arr.filter(v => {
			if (keywords && !v.name.includes(keywords)) return false;
			return true
		})
		list = searchList.filter((item, index) => index < pageSize * pageNum && index >= pageSize * (pageNum - 1))
	}
	var resp = {
		code: '200',
		list,
		pages: Math.ceil(arr.length / pageSize),
    total: arr.length
	}
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(resp)
		}, delay)
	})
}
