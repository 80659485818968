<template>
  <div class="d-flex bg-white p-10 border-box mb-10" @click="handleJump(item)">
    <img  class="d-block border-box flex-shrink-0 mr-10" v-lazy="item.logo || 'https://wemeet-1302608036.file.myqcloud.com/MEHZ/images/enterpriseLog.jpg' " alt="" style="width:90px;height: 90px; border-radius:6px;">
    <div class="flex-1 d-flex flex-column flex-wrap ml-auto">
      <div class="fz-14 fw-5" style="color: #292929">{{ item.exhibitorName }}</div>
      <div class="mt-10" style="color: #8c8c8c;font-size: 12px;">{{ item.booth || '' }}</div>
      <div>
        <div class="fz-12" style="margin-top: 7px;color: #858585;display: inline-block; padding: 2px 4px; border-radius: 3px;border:1px solid #E0E0E0;">{{ item.businessCategory }}</div>  
      </div>
      <div class="d-flex j-sb mt-auto" style="align-items: baseline;">
        <div class="fz-12" style="color: #858585">共{{item.exhibitNum}}个展品</div>
        <div class="fz-14" style="color: #00C4C9;padding: 5px 12px; border: 1px solid #00C4C9; border-radius: 6px;" v-if="item.enterpriseCode" @click.stop="enterEnterpirse(item)">进入荟客厅</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"ExhibitorItem",
  props:{
    item:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    meetingName:{
      type:String,
      default:''
    }
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    // 跳转展商详情页面
    handleJump(item) {
      this.$router.push({
        path: '/exhibitor/exhibitorDetail',
        query: {
          exhibitorCode: item.exhibitorCode,
        }
      })
    },
    enterEnterpirse(item){
      wx.miniProgram.navigateTo({
          url:`/subPackage/enterprise/pages/main-page/index?enterpriseCode=${item.enterpriseCode}&source=${this.meetingName}&way=shareMeeting`
        })
    }
  },
  mounted() {}
}
</script>
<style lang="less" scoped>
  .item { 
    background-color: #fff;
  }
</style>