import Vue from "vue";
import VueRouter from "vue-router";
import { setCookie, getCookie } from "@/utils/cookieinfo";
// 缓存原型上的push函数
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace
// 给原型对象上的push指定新函数函数
VueRouter.prototype.push = function (location, onComplete, onAbort) {
  // 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
  if (onComplete===undefined && onAbort===undefined) {
    return originPush.call(this, location, onComplete, onAbort).catch(() => {})
  } else { // 如果有指定任意回调函数, 通过call调用源push函数处理
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (onComplete===undefined && onAbort===undefined) {
    return originReplace.call(this, location, onComplete, onAbort).catch(() => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}
Vue.use(VueRouter);

const routes = [
  // 展商展品
   {
    path: "/exhibitor/exhibitorDetail",
    name: "exhibitorDetail",
    component: () => import("@/views/exhibitor/exhibitorDetail"),
    meta: {
      title: "展商详情",
    }
  },
  {
    path: "/exhibitor/exhibitorList",
    name: "exhibitorList",
    component: () => import("@/views/exhibitor/exhibitorList"),
    meta: {
      title: "展商列表",
    }
  },
  {
    path: "/exhibitor/exhibitsList",
    name: "exhibitsList",
    component: () => import("@/views/exhibitor/exhibitsList"),
    meta: {
      title: "展品列表",
    }
  },
  {
    path: "/exhibitor/exhibitsDetail",
    name: "exhibitsDetail",
    component: () => import("@/views/exhibitor/exhibitsDetail"),
    meta: {
      title: "展品详情",
    }
  },
  {
    path: "/jumpPage",
    name: "jumpPage",
    component: () => import("@/views/jumpPage"),
    meta: {
      title: "跳转中间页",
    }
  },
];

const router = new VueRouter({
  routes,
  // return 期望滚动到哪个的位置
  scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
           return savedPosition
      } else {
      return { x: 0, y: 0 }
   }
 }
});

router.beforeEach((to, from, next) => {
  let lang = getCookie("lang") || 1;
  if (to.query.lan) {
    lang = to.query.lan.toLowerCase() == "en" ? 2 : 1;
    setCookie("lang", 1);
  }
  next();
});

export default router;
