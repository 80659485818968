
// 公用配置，需要引入
export const mixins = {
	data() {
		return {}
	},
  created() {},
  watch:{
    "$route":{
      handler() {
        this.setPageTitle()
      }
    }
  },
  methods:{
    setPageTitle() {
      document.title = this.$route?.meta?.title || ""
    }
  }
}