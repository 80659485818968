<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import {changeSkin } from '@/utils/utils'
  export default{
    data(){
      return{}
    },
    created(){
     let eventkey = localStorage.getItem('eventkey') || ''
     changeSkin(eventkey)
    },
  }
</script>
<style lang="less">
@import "./assets/common.css";




</style>
