<template>
  <div class="ei-box animate__animated animate__fadeIn animate__fast">
    <div class="content">
      <div class="introduction border-box bg-white overflow-hidden">
        <div class="d-flex flex-column flex-wrap flex-start">
          <div class="fw-7 fz-14"  style="color:#292929;">企业介绍</div>
          <!-- <img class="d-block mt-10" v-lazy="item.logo" alt="" style="width: 47px;height: 47px;border-radius: 6px;" v-if="item.logo"> -->
        </div>
        <div class="desc-box d-flex a-start flex-wrap mt-10">
          <template v-if="item.details">
            <div class="desc fz-13 w-100 expansion-height" v-html="item.details" style="color: #858585"></div>
          </template>
          <template v-else>
            <div class="desc fz-13 w-100" style="color: #858585">暂无更多数据</div>
          </template>
        </div>
      </div>
      <div class="website border-box mt-10">
        <div class="fw-7 fz-14"  style="color:#292929;">企业官网</div>
        <div v-if="item.officialWebsite" class="d-flex justify-between" style="margin-top: 8px;">
          <div id="code" class="fz-13" style="color:#858585;word-break: break-all;width: 90%;">{{ item.officialWebsite }}</div>
          <span 
          id="copyWord" 
          class="iconfont h5fuzhi ml-auto"
          :data-clipboard-text="item.officialWebsite"
          style="width:14px;height: 14px;font-size:16px;color:#858585;" @click="handleCopyText"></span>
        </div>
        <div v-else class="fz-13" style="color:#858585;margin-top: 8px;">暂无</div>
      </div>
      <div class="contact-us border-box mt-10" v-if="contactArr.length!=0">
        <div class="fw-7 fz-14"  style="color:#292929;">联系我们</div>
        <div class="detail" style="line-height: 1">
          <div v-for="(sitem,index) in contactArr" :key="index" style="margin-top: 15px;border-radius: 10px;" @click="goContactDetai(sitem.uid)">
            <div style="color: #262626; font-size: 14px;margin-bottom: 10px;">{{ sitem.duty }}</div>
            <div class="contactBox">
              <div class="contactInfo d-flex">
                <img class="d-block" v-lazy="sitem.avatar" alt="" style="width: 48px;height: 48px;border-radius: 50%;" v-if="sitem.avatar">
                <div class="flex-1" style="margin-left: 6px;">
                  <div style="margin: 6px 0;font-size: 16px; color: #262626;">{{ sitem.name }}</div>
                  <div style="color: #8C8C8C;font-size: 12px;">{{ sitem.company }}{{ sitem.position?'·': ''}}{{ sitem.position }}</div>
                </div>
                <div class="d-flex j-center a-center" style="width: 34px;height: 34px; border-radius: 50%;background: #F5F5F5;margin-top: 6px;" @click.stop="takePhone(sitem.phone)">
                  <img src="../assets/phone.png" style="width:15px;height: 15px;" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'
export default {
  name:"ExhibitorInfo",
  props:{
    item:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    contactArr:{
      type:Array,
      default:[]
    }
  },

  components:{},
  data() {
    return {
      isCollapse: true
    }
  },
  computed: {
    detailsLength() {
      let details = this.item?.details 
      return details?.length
    }
  },
  methods: {
    handleCheckMore() {
      console.log(`output->查看更多`)
      this.isCollapse = !this.isCollapse;
    },
    takePhone(phone){
      // window.location.href = 'tel://' + phone
      window.location.href = 'tel:' + phone
    },
    goContactDetai(uid){
      wx.miniProgram.navigateTo({
        url:`/subPackage/social/pages/user-main-page/contactMainPage?targetUid=${uid}`
      })
    },
    handleCopyText() {
      console.log(`output->复制`)
      var _this = this
      var clipBoard = new Clipboard('#copyWord');
      clipBoard.on('success', function () {
        clipBoard.destroy();                        //销毁上一次的复制内容
        clipBoard = new Clipboard('#copyWord');
        _this.$toast.success('已成功复制到粘贴板')
      });
      clipBoard.on('error', function () {
        _this.$toast.fail('该浏览器不支持复制')
        clipboard.destroy()
      })
    },
  },
  mounted() {},
  beforeDestroy() {
    // clipboard && clipboard.destroy()
  }
}
</script>
<style lang="less" scoped >
  .ei-box {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 30px;    
    line-height: 1.3;  
    .content {
      margin: 0 10px;
      .introduction  {
        border-radius: 8px;
        padding: 14px 14px 22px;
        .desc-box {
          position: relative;
          line-height: 1.1;
          .desc {
            overflow: hidden;
            transition: all .3s ease;
          }
          .desc-default-height {
            height: 70px;
          }
          .expansion-height {
            height: auto;
          }
          .collapse {
            line-height: 1;
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 8px;
            box-sizing: border-box;
            margin: 0 auto;
            span:nth-child(2) {
              margin-left: 5px;
              margin-top:-2px;
              transition: all .3s ease;
            }
            .angle-up {
                transform: rotate(-90deg);
            }
            .angle-down {
              transform: rotate(90deg);
            }
          }
        }
      }
      .website {
        padding: 10px 14px 10px 14px;
        border-radius: 8px;
        background-color: #fff;
      }
      .contact-us {
        padding: 10px 14px 27px 14px;
        border-radius: 8px;
        background-color: #fff;
        .detail {
          div:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .contactBox{
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 10px;
  }
</style>